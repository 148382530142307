import React, { Fragment, useEffect, useRef, useState } from "react";
import cx from "classnames";
import { Card, Tooltip } from "@geist-ui/core";
import { ChevronDown, Eye } from "@geist-ui/icons";
import dayjs from "dayjs";
import { NumericFormat } from "react-number-format";

import { EBadgeMode, GeistBadge } from "../atoms/geistBadge";
import {
  toCamelCase,
  toCamelCaseWithFirstLetterCaps,
} from "../../assets/functions";
import { PerformenceBar } from "../atoms/performenceBar";
import { AiAccuracy } from "../atoms/accuracyScore";
import {
  getTicketSystemName,
  getUrlForTicketSystem,
} from "../../assets/functions/ticketSystems";

import styles from "./styles.module.scss";
import { EColumnTypes, EConventions, IColumn } from "./index.types";
import { StarsRating } from "./comps/starsRating";
import { GeistSelect } from "../geistSelect";

export const GDashboardTable = ({
  dataArray,
  selectedTopic,
  handleRowClick,
  columns,
  limit,
  disableSelected = false,
  loading = false,
  title,
  firstColumnWidth = "30%",
  isGrouping = false,
  isFooter = false,
  idName = "id",
  nextPage = false,
  onNextPage = () => {},
  loadMore = false,
  headerBorder = false,
  tablesWidth = "100%",
}: {
  dataArray: Array<Record<string, string>>;
  selectedTopic: number;
  handleRowClick: (rowId: string) => void;
  columns: IColumn[];
  limit?: number;
  disableSelected?: boolean;
  loading?: boolean;
  title?: string;
  isGrouping?: boolean;
  firstColumnWidth?: string;
  isFooter?: boolean;
  idName?: string;
  nextPage?: boolean;
  onNextPage?: () => void;
  loadMore?: boolean;
  headerBorder?: boolean;
  tablesWidth?: string;
}) => {
  const [showMore, setShowMore] = useState(false);
  const [array, setArray] = useState<Array<Record<string, string>>>([]);
  const [ellipsisStatus, setEllipsisStatus] = useState<boolean[]>([]);

  const textRefs = useRef<HTMLDivElement[]>([]);

  useEffect(() => {
    textRefs.current = textRefs.current.slice(0, array.length);
  }, [array.length]);

  useEffect(() => {
    if (!loading) {
      if (limit !== undefined) {
        if (showMore || dataArray.length <= limit) {
          setArray(dataArray);
        } else {
          setArray(dataArray.slice(0, limit));
        }
      } else {
        setArray(dataArray);
      }
      setEllipsisStatus(new Array(dataArray.length).fill(false));
    }
  }, [showMore, dataArray, limit, loading]);

  useEffect(() => {
    const checkEllipsis = () => {
      const ellipsisArray = textRefs.current.map((ref) => {
        return ref ? ref.scrollWidth > ref.clientWidth : false;
      });
      setEllipsisStatus(ellipsisArray);
    };

    checkEllipsis();
    window.addEventListener("resize", checkEllipsis);
    return () => window.removeEventListener("resize", checkEllipsis);
  }, [array]);

  const Wrapper = title ? "div" : Fragment;

  const displayedStringValue = (value: string, convention: EConventions) => {
    switch (convention) {
      case EConventions.CAMEL_CASE:
        return toCamelCase(value);
      case EConventions.FIRST_LETTER_CAMEL_CASE:
        return toCamelCaseWithFirstLetterCaps(value);
      default:
        return value;
    }
  };

  const handleButtonClick = ({
    e,
    seeOptions,
    rowValue,
    id,
  }: {
    e: React.MouseEvent<HTMLButtonElement>;
    seeOptions: (id: string) => void;
    rowValue: string;
    id: string;
  }) => {
    e.stopPropagation();
    if (seeOptions) {
      seeOptions(id);
    } else {
      const url = getUrlForTicketSystem(rowValue);
      if (url) {
        window.open(url, "_blank");
      }
    }
  };

  return (
    <Wrapper>
      {title && <h2 className={styles.title}>{title}</h2>}
      <Card
        style={{
          borderRadius: "12px",
        }}
        hoverable
        id="tableCard"
        className={cx(styles.dashboardTable, { [styles.loading]: loading })}
      >
        <div className={styles.cardContent}>
          {!loading && (
            <table className={styles.gtable} style={{ width: tablesWidth }}>
              <thead>
                <tr>
                  {columns.map((column, index) => (
                    <th
                      key={index}
                      className={cx(
                        { [styles.topic]: index === 0 },
                        {
                          [styles.columnBorder]: column.columnBorder,
                        },
                        {
                          [styles.headerBorder]: headerBorder,
                        },
                        {
                          [styles.rating]:
                            column.type === EColumnTypes.RATING ||
                            column.type === EColumnTypes.CHOOSE,
                        }
                      )}
                      style={{
                        width: column.forceWidth
                          ? column.forceWidth
                          : index === 0
                          ? firstColumnWidth
                          : "",
                      }}
                    >
                      {column.label}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr className={styles.divider}>
                  <td colSpan={columns.length} />
                </tr>
                {array.map((row: any, index: number) => {
                  if (isGrouping && row.isGroup === "true") {
                    return (
                      <tr
                        className={cx(styles.tr, {
                          [styles.selectedRow]:
                            index === selectedTopic && !disableSelected,
                        })}
                        onClick={() => handleRowClick(row[idName])}
                        key={index}
                      >
                        <td
                          className={styles.titleRow}
                          colSpan={columns.length}
                        >
                          {row.title}
                        </td>
                      </tr>
                    );
                  }
                  return (
                    <Fragment key={index}>
                      <tr
                        className={cx(styles.tr, {
                          [styles.selectedRow]:
                            index === selectedTopic && !disableSelected,
                          [styles.footer]:
                            isFooter && index === dataArray.length - 1,
                        })}
                        onClick={() => handleRowClick(row[idName])}
                        key={index}
                      >
                        {columns.map(
                          ({
                            value,
                            type,
                            badgeOptions,
                            trendOptions,
                            dataOptions,
                            seeOptions,
                            changeConvention,
                            handleBlur,
                            handleFocus,
                            onRate,
                            onHover,
                            selectOptions,
                            ratingOptions,
                            forceWidth,
                          }) => {
                            const display =
                              typeof row[value] === "string" && changeConvention
                                ? displayedStringValue(
                                    row[value],
                                    changeConvention
                                  )
                                : row[value];

                            switch (type) {
                              case EColumnTypes.TRACK:
                                return (
                                  <td key={value} className={styles.track}>
                                    <div>
                                      {(row[value] as unknown as number[])?.map(
                                        (n: number) => {
                                          return (
                                            <span
                                              className={cx(styles.trackItem, {
                                                [styles.red]: n < 3,
                                                [styles.yellow]:
                                                  n < 4 && n >= 3,
                                                [styles.green]:
                                                  n <= 5 && n >= 4,
                                              })}
                                            />
                                          );
                                        }
                                      )}
                                    </div>
                                  </td>
                                );
                              case EColumnTypes.EVALUATION:
                                return (
                                  <td
                                    key={value}
                                    className={cx(styles.evaluation, {
                                      [styles.red]:
                                        Number(row[value]) < 3 &&
                                        Number(row[value]) > 0,
                                      [styles.yellow]:
                                        Number(row[value]) < 4 &&
                                        Number(row[value]) >= 3,
                                      [styles.green]: Number(row[value]) >= 4,
                                      [styles.zero]: Number(row[value]) === 0,
                                    })}
                                  >
                                    {Number(row[value]) > 0 && <span />}
                                    {dataOptions?.round &&
                                    !Number.isInteger(row[value])
                                      ? row[value].toFixed(2)
                                      : row[value]}
                                  </td>
                                );
                              case EColumnTypes.INPUT:
                                if (
                                  isFooter &&
                                  index === dataArray.length - 1
                                ) {
                                  return (
                                    <td
                                      key={value}
                                      className={styles.badge}
                                      style={{ width: forceWidth }}
                                    >
                                      <GeistBadge
                                        mode={
                                          badgeOptions
                                            ? badgeOptions[display]
                                            : EBadgeMode.DEFAULT
                                        }
                                      >
                                        {display}
                                      </GeistBadge>
                                    </td>
                                  );
                                }
                                return (
                                  <td key={value} className={styles.input}>
                                    <NumericField
                                      key={value}
                                      index={index}
                                      handleBlur={handleBlur!}
                                      handleFocus={handleFocus!}
                                      value={row[value]}
                                    />
                                  </td>
                                );
                              case EColumnTypes.DATE:
                                return (
                                  <td key={value} className={styles.date}>
                                    {dayjs(row[value]).format("MMM D, YYYY")}
                                  </td>
                                );
                              case EColumnTypes.DATE_TIME:
                                return (
                                  <td key={value} className={styles.date}>
                                    {dayjs(row[value]).format(
                                      "MMM D, YYYY HH:mm"
                                    )}
                                  </td>
                                );
                              case EColumnTypes.NAME:
                                return (
                                  <td key={value}>
                                    <div
                                      ref={(el) => {
                                        if (el !== null) {
                                          textRefs.current[index] = el;
                                        }
                                      }}
                                    >
                                      {ellipsisStatus[index] ? (
                                        <Tooltip
                                          text={display}
                                          placement="topStart"
                                        >
                                          {display}
                                        </Tooltip>
                                      ) : (
                                        display
                                      )}
                                    </div>
                                  </td>
                                );
                              case EColumnTypes.BADGE:
                                return (
                                  <td key={value} className={styles.badge}>
                                    <GeistBadge
                                      mode={
                                        badgeOptions
                                          ? badgeOptions[display]
                                          : EBadgeMode.DEFAULT
                                      }
                                    >
                                      {display}
                                    </GeistBadge>
                                  </td>
                                );
                              case EColumnTypes.PERCENTAGE:
                                return (
                                  <td key={value} className={styles.data}>
                                    {`${Math.round(100 * Number(row[value]))}%`}
                                  </td>
                                );
                              case EColumnTypes.TREND:
                                return (
                                  <td
                                    key={value}
                                    className={cx(styles.data, {
                                      [styles.green]: Number(row[value]) > 0,
                                      [styles.red]: Number(row[value]) < 0,
                                    })}
                                  >
                                    {`${Math.round(100 * Number(row[value]))}%`}
                                    {trendOptions &&
                                      ` ${
                                        trendOptions[
                                          Number(row[value]) > 0
                                            ? "good"
                                            : Number(row[value]) < 0
                                            ? "bad"
                                            : "default"
                                        ]
                                      }`}
                                  </td>
                                );
                              case EColumnTypes.TIME:
                                const time = Number(row[value]);
                                return (
                                  <td key={value} className={styles.data}>
                                    {time > 60 * 24
                                      ? dayjs
                                          .duration(time, "minutes")
                                          .format("D[D] H[H]")
                                      : dayjs
                                          .duration(time, "minutes")
                                          .format("H[H] m[M]")}
                                  </td>
                                );
                              case EColumnTypes.PERFORMANCE:
                                return (
                                  <td key={value}>
                                    <PerformenceBar value={row[value]} />
                                  </td>
                                );
                              case EColumnTypes.ACCURACY:
                                return (
                                  <td key={value}>
                                    <AiAccuracy accuracy={row[value]} />
                                  </td>
                                );
                              case EColumnTypes.BUTTON:
                                return (
                                  <td className={styles.data}>
                                    <button
                                      className={styles.linkToTicket}
                                      onClick={(e) =>
                                        handleButtonClick({
                                          e,
                                          seeOptions: () =>
                                            seeOptions
                                              ? seeOptions(row[idName])
                                              : () => {},
                                          rowValue: row[value],
                                          id: row[idName],
                                        })
                                      }
                                    >
                                      <Eye />
                                      View{" "}
                                      {!seeOptions
                                        ? `in ${getTicketSystemName(
                                            row[value]
                                          )}`
                                        : ""}
                                    </button>
                                  </td>
                                );
                              case EColumnTypes.TEXT:
                                return (
                                  <td key={value} className={styles.input}>
                                    <TextField
                                      key={value}
                                      index={index}
                                      handleBlur={(_, text: string) =>
                                        handleBlur!(row[idName], text)
                                      }
                                      handleFocus={handleFocus!}
                                      value={row[value]}
                                      disabled={
                                        row["metric"] === "Overall Score"
                                      }
                                    />
                                  </td>
                                );
                              case EColumnTypes.CHOOSE:
                                const options =
                                  selectOptions?.optionsChooseFilter.find(
                                    (entry) => entry.question === row["metric"]
                                  );

                                console.log({
                                  value: row[value],
                                  options: options?.answers,
                                });
                                return (
                                  <td
                                    key={value}
                                    className={cx(styles.data, styles.rating)}
                                  >
                                    <GeistSelect
                                      options={
                                        options?.answers.map((answer) => {
                                          return {
                                            value: answer.title,
                                            label: answer.title,
                                          };
                                        }) || []
                                      }
                                      handleChange={
                                        (v) =>
                                          selectOptions?.handleChangeChooseFilter(
                                            row[idName],
                                            v as any
                                          ) // to prevent
                                      }
                                      initialValue={
                                        row[value]
                                          ? {
                                              value: row[value].toUpperCase(),
                                              label: row[value].toUpperCase(),
                                            }
                                          : {
                                              value: "None Reason",
                                              label: "None Reason",
                                            }
                                      }
                                      disabled={
                                        row["metric"] === "Overall Score"
                                      }
                                    />
                                  </td>
                                );
                              case EColumnTypes.STARS_RATING:
                                return (
                                  <td
                                    key={value}
                                    className={styles.data}
                                    onMouseEnter={() =>
                                      onHover ? onHover(row[idName]) : () => {}
                                    }
                                  >
                                    <StarsRating
                                      initialValue={row[value]}
                                      onRate={(value: number) =>
                                        onRate
                                          ? onRate({
                                              value,
                                              articleId: row[idName],
                                            })
                                          : () => {}
                                      }
                                    />
                                  </td>
                                );
                              case EColumnTypes.RATING:
                                const type = ratingOptions?.type;
                                const handleChangeRatingFilter =
                                  ratingOptions?.handleChangeRatingFilter ||
                                  (() => {});
                                const ratingList =
                                  type === "binary" ? [0, 1] : [1, 2, 3, 4, 5];
                                return (
                                  <td
                                    key={value}
                                    className={cx(styles.data, styles.rating)}
                                    style={{ width: forceWidth }}
                                  >
                                    <GeistSelect
                                      options={
                                        ratingList.map((answer) => {
                                          return {
                                            value: answer.toString(),
                                            label: answer.toString(),
                                          };
                                        }) || []
                                      }
                                      handleChange={(v) =>
                                        handleChangeRatingFilter(
                                          row[idName],
                                          v as any
                                        )
                                      }
                                      initialValue={{
                                        value: row[value],
                                        label: row[value],
                                      }}
                                      disabled={
                                        row["metric"] === "Overall Score"
                                      }
                                    />
                                  </td>
                                );

                              default:
                                return (
                                  <td key={value} className={styles.data}>
                                    {dataOptions && dataOptions.round
                                      ? Number(row[value]) % 1 === 0
                                        ? Number(row[value])
                                        : Number(row[value]).toFixed(2)
                                      : row[value]}
                                  </td>
                                );
                            }
                          }
                        )}
                      </tr>

                      <tr className={styles.divider}>
                        <td colSpan={columns.length} />
                      </tr>
                    </Fragment>
                  );
                })}
                {limit !== undefined && dataArray.length > limit && (
                  <tr className={styles.showMore}>
                    <td colSpan={columns.length}>
                      <div>
                        <span />
                        <button
                          onClick={() => setShowMore(!showMore)}
                          className={cx({ [styles.showMore]: showMore })}
                        >
                          <ChevronDown />
                        </button>
                        <span />
                      </div>
                    </td>
                  </tr>
                )}
                {(nextPage || loadMore) && (
                  <tr
                    className={cx(styles.showMore, {
                      [styles.loading]: loadMore,
                    })}
                  >
                    <td colSpan={columns.length}>
                      <div>
                        <span />
                        <button onClick={() => onNextPage()}>
                          Load More <ChevronDown />
                        </button>
                        <span />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </Card>
    </Wrapper>
  );
};

const NumericField = ({
  index,
  value,
  handleBlur,
  handleFocus,
}: {
  index: number;
  value: string;
  handleBlur: (index: number, value: string) => void;
  handleFocus: (index: number) => void;
}) => {
  const [v, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <NumericFormat
      onChange={(e) => {
        let number = e.target.value;

        if (+number < 5) {
          setValue(number);
        }
      }}
      onBlur={(e) => {
        handleBlur(index, e.target.value);
      }}
      onFocus={(e) => {
        handleFocus(index);
        e.target.select();
      }}
      value={v}
      allowNegative={false}
      decimalScale={0}
      fixedDecimalScale
      isAllowed={(values) => {
        const { floatValue } = values;

        let number = floatValue;

        return (number! < 5 && number! > 0) || !number;
      }}
    />
  );
};

const TextField = ({
  index,
  value,
  handleBlur,
  handleFocus,
  disabled = false,
}: {
  index: number;
  value: string;
  handleBlur: (index: number, value: string) => void;
  handleFocus: (index: number) => void;
  disabled?: boolean;
}) => {
  const [v, setValue] = useState(value);

  useEffect(() => {
    setValue(value);
  }, [value]);

  return (
    <input
      type="text"
      onChange={(e) => {
        const inputValue = e.target.value;
        setValue(inputValue);
      }}
      onBlur={(e) => {
        handleBlur(index, e.target.value);
      }}
      onFocus={(e) => {
        handleFocus(index);
        e.target.select();
      }}
      value={v}
      disabled={disabled}
    />
  );
};
