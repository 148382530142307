import { EBadgeMode } from "../atoms/geistBadge";

export enum EColumnTypes {
  DEFAULT = "data",
  DATE = "date",
  DATE_TIME = "dateTime",
  TIME = "time",
  NAME = "name",
  BADGE = "badge",
  PERCENTAGE = "percentage",
  TREND = "trend",
  INPUT = "input",
  TRACK = "track",
  EVALUATION = "evaluation",
  PERFORMANCE = "performance",
  ACCURACY = "accuracy",
  BUTTON = "button",
  STARS_RATING = "starsRating",
  TEXT = "text",
  CHOOSE = "choose",
  RATING = "rating",
}

export enum ETicketScoreTitle {
  KNOWLEDGE_SCORE = "productKnowledge",
  COM_SCORE = "communication",
  PROBLEM_SOLVING_SCORE = "problemSolving",
  TECH_AND_PRODUCT_SCORE = "technical-knowledge",
  ESCALATION_PROCESS_SCORE = "guidelines",
}

export enum EConventions {
  CAMEL_CASE = "CAMEL_CASE",
  FIRST_LETTER_CAMEL_CASE = "FIRST_LETTER_CAMEL_CASE",
}

export interface IColumn {
  label: string;
  value: string;
  type: EColumnTypes;
  badgeOptions?: Record<string, EBadgeMode>;
  trendOptions?: { good: string; bad: string; default: string };
  dataOptions?: { round?: boolean };
  seeOptions?: (id: string) => void;
  columnBorder?: boolean;
  changeConvention?: EConventions;
  handleBlur?: (row: number, value: string) => void;
  handleFocus?: (row: number) => void;
  onRate?: ({ value, articleId }: { value: number; articleId: string }) => void;
  onHover?: (id: string) => void;
  forceWidth?: string;
  selectOptions?: {
    optionsChooseFilter: {
      question: string;
      answers: { title: string; description: string }[];
    }[];
    handleChangeChooseFilter: (id: number, value: string) => void;
  };
  ratingOptions?: {
    type: "binary" | "multiple";
    handleChangeRatingFilter: (id: number, value: string) => void;
  };
}
